



























import {Component, Mixins} from 'vue-property-decorator'
import {MixinScreenSize} from '@/components/mixins/MixinScreenSize'
import ConnectWalletModal from '@/components/walletConnection/ConnectWalletModal.vue'
import {$} from '@/facade'

@Component({
  components: {ConnectWalletModal},
})
export default class GetGasButton extends Mixins(MixinScreenSize) {}
